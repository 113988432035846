const types = {
  // Login and Registration
  LOGIN_REQUEST: 'session/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'session/LOGIN_SUCCESS',
  LOGIN_ERROR: 'session/LOGIN_ERROR',
  LOGOUT: 'session/LOGOUT',
  REGISTER_REQUEST: 'session/REGISTER_REQUEST',
  REGISTER_SUCCESS: 'session/REGISTER_SUCCESS',
  REGISTER_ERROR: 'session/REGISTER_ERROR',
  REFRESH_CURRENT_REQUEST: 'session/REFRESH_CURRENT_REQUEST',
  REFRESH_CURRENT_SUCCESS: 'session/REFRESH_CURRENT_SUCCESS',
  REFRESH_CURRENT_ERROR: 'session/REFRESH_CURRENT_ERROR',
  // Global
  GLOBAL_FETCH_START: 'global/FETCH_START',
  GLOBAL_FETCH_FINISH: 'global/FETCH_FINISH',
  GLOBAL_FETCH_ERROR: 'global/FETCH_ERROR',
  IS_MODAL_LOGOUT_OPEN: 'global/IS_MODAL_LOGOUT_OPEN',
  IS_MODAL_LOGOUT_CLOSE: 'global/IS_MODAL_LOGOUT_CLOSE',
  IS_MODAL_DELETE_OPEN: 'global/IS_MODAL_DELETE_OPEN',
  IS_MODAL_DELETE_CLOSE: 'global/IS_MODAL_DELETE_CLOSE',
  OPEN_MODALADDTRANSACTION: 'OPEN_MODALADDTRANSACTION',
  CLOSE_MODALADDTRANSACTION: 'CLOSE_MODALADDTRANSACTION',
  OPEN_MODALLGOUTTRANSACTION: 'OPEN_MODALLGOUTTRANSACTION',
  CLOSE_MODALLGOUTTRANSACTION: 'CLOSE_MODALLGOUTTRANSACTION',
  // Finance
  FINANCE_DATA_FETCH_START: 'FINANCE_DATA_FETCH_START',
  FINANCE_DATA_FETCH_FINISH: 'FINANCE_DATA_FETCH_FINISH',
  FINANCE_DATA_ERROR: 'FINANCE_DATA_ERROR',
  FINANCE_TOTAL_BALANCE_FETCH_START: 'FINANCE_TOTAL_BALANCE_FETCH_START',
  FINANCE_TOTAL_BALANCE_FETCH_FINISH: 'FINANCE_TOTAL_BALANCE_FETCH_FINISH',
  FINANCE_TOTAL_BALANCE_ERROR: 'FINANCE_TOTAL_BALANCE_ERROR',
  FINANCE_TYPE_TOTAL_BALANCE_FETCH_START:
    'FINANCE_TYPE_TOTAL_BALANCE_FETCH_START',
  FINANCE_FETCH_TYPE_TOTAL_BALANCE_FINISH:
    'FINANCE_FETCH_TYPE_TOTAL_BALANCE_FINISH',
  FINANCE_TYPE_TOTAL_BALANCE_ERROR: 'FINANCE_TYPE_TOTAL_BALANCE_ERROR',
  FINANCE_ADD_TRANSACTION_START: 'FINANCE_ADD_TRANSACTION_START',
  FINANCE_ADD_TRANSACTION_FINISH: 'FINANCE_ADD_TRANSACTION_FINISH',
  FINANCE_ADD_TRANSACTION_ERROR: 'FINANCE_ADD_TRANSACTION_ERROR',
  FINANCE_DELETE_TRANSACTION_START: 'FINANCE_DELETE_TRANSACTION_START',
  FINANCE_DELETE_TRANSACTION_FINISH: 'FINANCE_DELETE_TRANSACTION_FINISH',
  FINANCE_DELETE_TRANSACTION_ERROR: 'FINANCE_DELETE_TRANSACTION_ERROR',
  // set token in store
  SET_TOKEN_IN_STORE: 'SET_TOKEN_IN_STORE',
};

export default types;
